// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import PT from "prop-types";
import { CONTACT_STATUS, LOCAL_STORAGE_KEYS } from "../../constants/global";
import ChatTranscriptor from "./ChatTranscriptor";
import ChatComposer from "./ChatComposer";
import ChatActionBar from "./ChatActionBar";
import React, { Component } from "react";
import { Text } from "../core";
import styled from "styled-components";

import renderHTML from "react-render-html";
import { getText } from "../../utils/pantherHelper";
import { clearPreviousSession } from "./ChatContainer";
import { StencilProvider } from "@amzn/stencil-react-components/context";
import { Direction, PARTICIPANT_TYPES } from "./datamodel/Model";
import ChatTimeout from "./ChatTimeout/ChatTimeout";

const ChatWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const ParentHeaderWrapper = styled.div`
  margin: 0;
  padding: 0;
  order: 1;
  height: 10%;
`;

const ChatComposerWrapper = styled.div`
  order: 2;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 80%;
`;

const HeaderWrapper = styled.div`
  background: #3f5773;
  text-align: center;
  color: #fff;
  border-radius: 3px;
  flex-shrink: 0;
  height: 100%;
`;
const WelcomeText = styled(Text)`
  margin: 0;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

Header.defaultProps = {
  headerConfig: {},
};

function Header({ headerConfig, bundle }) {
  const config = Object.assign(
    {},
    {
      isHTML: false,
      render: () => {
        return (
          <HeaderWrapper>
            <WelcomeText type={"h2"}>
              {getText(bundle, "virtual-assistant")}
            </WelcomeText>
          </HeaderWrapper>
        );
      },
    },
    headerConfig
  );

  if (config.isHTML) {
    return renderHTML(config.render());
  } else {
    return config.render();
  }
}

const textInputRef = React.createRef();
const HEADER_HEIGHT = 51;

export default class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transcript: [],
      typingParticipants: [],
      contactStatus: CONTACT_STATUS.DISCONNECTED,
      parentHeaderWrapperHeight: HEADER_HEIGHT,
      enableTextInput: false,
    };
    this.parentHeaderRef = React.createRef();
    this.updateTranscript = (transcript) => {
      this.setBotTypingStatus(transcript); // Sets the status whether the bot is typing or not which will be used in transcriptor to render status
      this.enableTextInputBasedOnConditions(transcript, this.props.bundle);
      this.setState({ transcript: [...transcript] });
    };

    this.updateTypingParticipants = (typingParticipants) =>
      this.setState({ typingParticipants });
    this.updateContactStatus = (contactStatus) =>
      this.setState({ contactStatus });
    if (window.connect && window.connect.LogManager) {
      this.logger = window.connect.LogManager.getLogger({
        prefix: "ChatInterface-Chat",
      });
    }
  }

  static propTypes = {
    displayChat: PT.bool,
    chatSession: PT.object.isRequired,
    composerConfig: PT.object,
    onEnded: PT.func,
  };

  static defaultProps = {
    onEnded: () => {},
  };

  isBotTyping() {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.IS_BOT_TYPING) === "true";
  }

  setBotTypingStatus(transcript) {
    /* The logic here is that the bot is assumed to be typing if the last message was from the user.
    That is until an agent has joined the chat. In that case, this status will have lower priority
    compared to the actual typing status of the agent. That priority is first driven by the entry of
    an agent into the chat, set by checking if the there is a participant with role "AGENT" here and
    the status of that agent's actual typing in the transcriptor component.
    */
    const status =
      transcript.length === 0 ||
      transcript[transcript.length - 1]?.transportDetails?.direction ===
        Direction.Outgoing
        ? "true"
        : "false";
    if (status === "true") {
      document.getElementById(`aria-message`).textContent = "Ava is typing";
    } else {
      document.getElementById(`aria-message`).textContent =
        transcript[transcript.length - 1].content.data;
    }

    localStorage.setItem(LOCAL_STORAGE_KEYS.IS_BOT_TYPING, status);
  }

  enableTextInputBasedOnConditions(transcript, bundle) {
    if (this.isVirtualAssistant(transcript)) {
      // enable text input for candidates only if last message is from bot and message is not "Agent is typing"
      if (!this.isBotTyping()) {
        this.setState({ enableTextInput: true });
      } else {
        this.setState({ enableTextInput: false });
      }
    } else {
      // OTP related translations - currently only US has OTP validation flow
      // TODO: use a better way to detect if candidates need to input OTP to decouple the translations and validation logic
      const text = [
        getText(bundle, "intent-authentication-otp-question"),
        getText(bundle, "intent-authentication-otp-error-message"),
      ];
      // First check: display text input for candidates if they need to input OTP and then disable it once done
      if (
        transcript.length > 0 &&
        text.includes(transcript[transcript.length - 1]?.content?.data)
      ) {
        this.setState({ enableTextInput: true });
      } else {
        this.setState({ enableTextInput: false });
      }
    }

    if (
      transcript.some(
        (element) => element.participantRole === PARTICIPANT_TYPES.AGENT
      )
    ) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.AGENT_HAS_JOINED_CHAT, "true"); // This marks the entry of the agent into the chat.
      this.setState({ enableTextInput: true });
    }
  }

  isVirtualAssistant(transcript) {
    if (this.props.virtualAssistantEnable === false) return false;
    // TODO: use a better way to detect if candidate is currently talking to Virtual Assistant or legacy bot
    const legacyBotMessageIdx = transcript.findIndex((element) =>
      element.content.data?.includes("Virtual Assistant")
    );
    return legacyBotMessageIdx === -1;
  }

  resetChatHeight() {
    this.setState({
      parentHeaderWrapperHeight:
        this.parentHeaderRef && this.parentHeaderRef.current
          ? this.parentHeaderRef.current.clientHeight
          : HEADER_HEIGHT,
    });
  }

  componentDidMount() {
    this.init(this.props.chatSession);
    this.resetChatHeight();
    this.logger && this.logger.info("Component mounted.");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chatSession !== this.props.chatSession) {
      this.cleanUp(prevProps.chatSession);
      this.init(this.props.chatSession);
    }
  }

  componentWillUnmount() {
    this.cleanUp(this.props.chatSession);
  }

  init(chatSession) {
    this.setState({ contactStatus: chatSession.contactStatus });
    chatSession.on("transcript-changed", this.updateTranscript);
    chatSession.on(
      "typing-participants-changed",
      this.updateTypingParticipants
    );
    chatSession.on("contact-status-changed", this.updateContactStatus);
  }

  cleanUp(chatSession) {
    chatSession.off("transcript-changed", this.updateTranscript);
    chatSession.off(
      "typing-participants-changed",
      this.updateTypingParticipants
    );
    chatSession.off("contact-status-changed", this.updateContactStatus);
  }

  endChat() {
    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_HAS_JOINED_CHAT, "false"); // This marks the exit of the user into the chat
    localStorage.setItem(LOCAL_STORAGE_KEYS.AGENT_HAS_JOINED_CHAT, "false"); // This marks the exit of the agent from the chat
    clearPreviousSession();
    this.props.chatSession.endChat();
    this.props.onEnded();
  }

  closeChat() {
    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_HAS_JOINED_CHAT, "false"); // This marks the exit of the user into the chat
    localStorage.setItem(LOCAL_STORAGE_KEYS.AGENT_HAS_JOINED_CHAT, "false"); // This marks the exit of the agent from the chat
    this.props.chatSession.closeChat();
  }
  /*
  Note: For Mobile layout: divided into 3 sections
  1. Header - Positon: absolute; top: 0, left: 0, right: 0 - height is dynamic!
  2. MainContent - Position: absolute; top: {dynamicHeight}, left: 0, right: 0, bottom: {fixedFooterHeight: 85px}
  3. Footer - position: absolute; bottom: 0, right: 0, left: 0
  -- this prevents overlay from overflowing in mobile browser. 
*/
  render() {
    const {
      chatSession,
      headerConfig,
      transcriptConfig,
      composerConfig,
      footerConfig,
    } = this.props;
    console.log("MESSAGES", this.state.transcript);

    return (
      <StencilProvider>
        <ChatWrapper
          data-testid="amazon-connect-chat-wrapper"
          style={{ display: true === this.props.displayChat ? "" : "none" }}
        >
          {this.isVirtualAssistant(this.state.transcript) && (
            <ChatTimeout
              transcript={this.state.transcript}
              bundle={this.props.bundle}
            />
          )}
          {(this.state.contactStatus === CONTACT_STATUS.CONNECTED ||
            this.state.contactStatus === CONTACT_STATUS.CONNECTING ||
            this.state.contactStatus === CONTACT_STATUS.ENDED) && (
            <ParentHeaderWrapper
              data-testid="parent-header-wrapper"
              ref={this.parentHeaderRef}
            >
              <Header headerConfig={headerConfig} {...this.props} />
            </ParentHeaderWrapper>
          )}
          <ChatComposerWrapper
            data-testid="chat-composer-wrapper"
            parentHeaderWrapperHeight={this.state.parentHeaderWrapperHeight}
          >
            <ChatTranscriptor
              loadPreviousTranscript={() =>
                chatSession.loadPreviousTranscript()
              }
              addMessage={(data) => chatSession.addOutgoingMessage(data)}
              downloadAttachment={(attachmentId) =>
                chatSession.downloadAttachment(attachmentId)
              }
              transcript={this.state.transcript}
              isBotTyping={this.isBotTyping()}
              typingParticipants={this.state.typingParticipants}
              contactStatus={this.state.contactStatus}
              contactId={chatSession.contactId}
              transcriptConfig={transcriptConfig}
              textInputRef={textInputRef}
              sendReadReceipt={(...inputParams) =>
                chatSession.sendReadReceipt(...inputParams)
              }
              {...this.props}
            />
            <ChatComposer
              contactStatus={this.state.contactStatus}
              contactId={chatSession.contactId}
              addMessage={(contactId, data) =>
                chatSession.addOutgoingMessage(data)
              }
              addAttachment={(contactId, attachment) =>
                chatSession.addOutgoingAttachment(attachment)
              }
              onTyping={() => chatSession.sendTypingEvent()}
              composerConfig={composerConfig}
              textInputRef={textInputRef}
              enableTextInput={this.state.enableTextInput}
              bundle={this.props.bundle}
              inputEditorDir={this.props.inputEditorDir}
              chatIsReady={this.props.displayChat}
            />
          </ChatComposerWrapper>
          {
            <ChatActionBar
              data-testid="chat-action-bar"
              onEndChat={() => this.endChat()}
              onClose={() => this.closeChat()}
              contactStatus={this.state.contactStatus}
              sendFeedback={(data) => chatSession.addOutgoingMessage(data)}
              isVirtualAssistant={this.isVirtualAssistant(
                this.state.transcript
              )}
              footerConfig={footerConfig}
              {...this.props}
            />
          }
        </ChatWrapper>
      </StencilProvider>
    );
  }
}
