import React, { useCallback, useEffect, useRef, useState } from "react";
import { useBundle } from "@amzn/react-arb-tools";
import { getFormattedText, getText } from "./utils/pantherHelper";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Input, InputFooter } from "@amzn/stencil-react-components/form";
import { Label } from "@amzn/stencil-react-components/text";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Card } from "@amzn/stencil-react-components/card";
import { H1 } from "@amzn/stencil-react-components/text";
import { Spinner } from "@amzn/stencil-react-components/spinner";
import { SpinnerSize } from "@amzn/stencil-react-components/dist/submodules/spinner/interfaces";
import { redirectTrafficToConnectByPercentage } from "./utils/dialUpHelper";
import { Link, BrowserRouter } from "react-router-dom";

export const InputCard = ({
  initChatApp,
  inputEditorDir,
  language,
  country,
  jobType,
  urlPrefix,
  connect,
}) => {
  const [bundle, isBundleLoading] = useBundle("strings");
  const [emailAddress, setEmailAddress] = useState("");
  const [isEmailAddressValid, setIsEmailAddressValid] = useState(true);
  const [loading, setLoading] = useState(true);
  const inputRef = useRef(null);

  const loadingComponent = (
    <Col height={"100vh"} alignItems={"center"} justifyContent={"center"}>
      <Spinner size={SpinnerSize.Medium} showText={false} />
    </Col>
  );

  const requestDialUpConfig = useCallback(async () => {
    setLoading(true);
    try {
      await redirectTrafficToConnectByPercentage(
        language,
        country,
        jobType,
        urlPrefix,
        connect
      );
    } catch (e) {}
    setLoading(false);
  }, [language, country, jobType, urlPrefix, connect]);

  useEffect(() => {
    requestDialUpConfig().then((r) => {});
  }, [requestDialUpConfig]);

  if (isBundleLoading) {
    return loadingComponent;
  }

  if (loading) {
    return loadingComponent;
  }

  const submitEmailAddress = () => {
    if (!emailAddress || !validateEmail(emailAddress)) {
      setIsEmailAddressValid(false);
      inputRef.current?.focus();
    } else {
      initChatApp(emailAddress);
    }
  };

  return (
    <Col alignItems={"center"} padding={"7rem 1rem 0 1rem"} gridGap={"2rem"}>
      <H1>{getText(bundle, "input-card-header")}</H1>
      <Card
        justifyContent="center"
        alignItems="center"
        maxWidth={800}
        width={"100%"}
        backgroundColor={"#f2f4f7"}
      >
        <Col
          gridGap={"3rem"}
          width={"100%"}
          height={"100%"}
          alignItems={"center"}
        >
          <Col width={"100%"} gridGap={"1rem"}>
            <Row>
              <Label htmlFor="email-address-input">
                {getText(bundle, "input-card-email-address")}{" "}
                <strong style={{ color: "red" }} aria-hidden="true">
                  *
                </strong>{" "}
              </Label>
            </Row>

            <Input
              aria-required="true"
              dir={inputEditorDir}
              id="email-address-input"
              ref={inputRef}
              type="text"
              placeholder="example@email.com"
              aria-invalid={!isEmailAddressValid ? "true" : "false"}
              aria-describedby="email-address-error"
              onChange={(e) => {
                setIsEmailAddressValid(true);
                setEmailAddress(e.target.value);
              }}
              error={!isEmailAddressValid}
            />
            {!isEmailAddressValid ? (
              <InputFooter id="email-address-error" error>
                {getText(bundle, "input-card-error-message")}
              </InputFooter>
            ) : null}
          </Col>
          <Col width={250}>
            <Button
              variant={ButtonVariant.Primary}
              onClick={submitEmailAddress}
            >
              {getText(bundle, "input-card-chat-now-button")}
            </Button>
          </Col>
        </Col>
      </Card>
      <Card
        justifyContent="center"
        alignItems="center"
        maxWidth={800}
        width={"100%"}
        backgroundColor={"#f2f4f7"}
      >
        <Col>
          <Label
            fontSize={"0.5em"}
            display={"inline"}
            data-testid="terms-and-conditions"
          >
            {getFormattedText(bundle, "terms-and-conditions-message", {
              hiringProcessUrl: (
                <Col style={{ display: "inline" }} key={"hiring-process"}>
                  <BrowserRouter>
                    <Link
                      to={{
                        pathname: "https://hiring.amazon.com/hiring-process#/",
                      }}
                      target="_blank"
                    >
                      {getText(bundle, "hiring-process")}
                    </Link>
                  </BrowserRouter>
                </Col>
              ),
              applicationGuideUrl: (
                <Col style={{ display: "inline" }} key={"application-guide"}>
                  <BrowserRouter>
                    <Link
                      to={{
                        pathname:
                          "https://hiring.amazon.com/hiring-process/application-guide#/",
                      }}
                      target="_blank"
                    >
                      {getText(bundle, "application-guide")}
                    </Link>
                  </BrowserRouter>
                </Col>
              ),
            })}
          </Label>
        </Col>
      </Card>
    </Col>
  );
};

export const validateEmail = (emailAddress) => {
  return String(emailAddress)
    .toLowerCase()
    .match(/^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/); //matching the regex similar to HBA registration page, this is to fix issue with accented characters mentioned in https://issues.amazon.com/issues/T-STARSHIP-1334
};
