import { useEffect, useState } from "react";
import { Modal } from "@amzn/stencil-react-components/modal";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { getText } from "../../../utils/pantherHelper";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import * as React from "react";

export default function ChatTimeout({ transcript, bundle }) {
  const [
    isSessionTimeoutReminderOpen,
    setSessionTimeoutReminderOpen,
  ] = useState(false);

  useEffect(() => {
    let interval;
    // Add a timeout to prevent below logic from running before chat session
    const timer = setTimeout(() => {
      interval = setInterval(() => {
        // Run every 45 seconds
        if (
          transcript.length > 0 &&
          transcript[transcript.length - 1].participantRole === "SYSTEM"
        ) {
          const currentTime = Math.floor(Date.now() / 1000);
          const lastTimeBotResponded =
            transcript[transcript.length - 1].transportDetails.sentTime;
          const timeDifference = currentTime - lastTimeBotResponded;
          if (timeDifference >= 2100) {
            // 35 minutes
            setSessionTimeoutReminderOpen(true);
          }
        }
      }, 45000);
    }, 15000);

    // Clean up the interval and timeout when the component unmounts
    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [transcript]);

  const closeSessionTimeoutReminder = () => {
    setSessionTimeoutReminderOpen(false);
  };

  const sessionTimeoutReminder = (
    <Modal
      isOpen={isSessionTimeoutReminderOpen}
      close={closeSessionTimeoutReminder}
    >
      <Col
        backgroundColor="neutral0"
        gridGap="2rem"
        padding="2rem"
        alignItems="center"
        role={"dialog"}
        aria-label={"Chat session timeout reminder"}
      >
        <Text>{getText(bundle, "session-timeout-message")}</Text>
        <Row gridGap="1rem">
          <Button
            onClick={closeSessionTimeoutReminder}
            variant={ButtonVariant.Primary}
          >
            {getText(bundle, "session-timeout-acknowledge")}
          </Button>
        </Row>
      </Col>
    </Modal>
  );

  return <Col>{sessionTimeoutReminder}</Col>;
}
